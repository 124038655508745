// @codekit-prepend "/js/firebase-init.js";
// @codekit-prepend "/js/utils.js";
// @codekit-prepend "/vendor/vue.js";


var params = new URL(document.location).searchParams;
var token = params.get("token");

if (token) {
    firebase.auth().signInWithCustomToken(token).catch(function (error) {
        console.log(error.code, error.message);
    }
    );
}

firebase.auth().onAuthStateChanged(function (data) {
    if (data) {
        window.location = "/";
    }
});

var sensePathApp = new Vue({
    el: "#sense-path-app",
    data: function () {
        return {
            error: '',
            username: '',
            password: ''
        };
    },
    methods: {
        login: function () {
            firebase
                .auth()
                .signInWithEmailAndPassword(this.username, this.password)
                .then(function () {
                    // sign in successful
                })
                .catch(function (error) {
                    if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                        sensePathApp.error = "Either your email address or password is incorrect. Please try again.";
                    } else {
                        sensePathApp.error = error.message;
                    }
                });
        }
    }
});